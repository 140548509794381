define(['app', '$window'], function(app, $window) {
  var doubleOptInToast = function() {
    var comp = {};

    var _init = function(el) {
      comp.element = el;

      comp.textEl = el.querySelector(comp.config.selectors.text);
      comp.exitEl = el.querySelector(comp.config.selectors.exit);
      comp.text = {};

      var props = el.querySelectorAll(comp.config.selectors.property);

      for (var i = 0; i < props.length; i++) {
        var property = props[i].getAttribute(comp.config.attribs.property);
        var value = props[i].getAttribute(comp.config.attribs.value);

        if (!property || !value) {
          continue;
        }

        comp.text[property] = value;
      }

      // check if this text was already seen
      var saved = ($window.sessionStorage.getItem(comp.config.session) || '').split('|');

      if (!comp.text.confirmText || saved.indexOf(comp.text.confirmText) !== -1) {
        comp.exit(false);

        return;
      }

      comp.exitEl.addEventListener('click', comp.exit.bind(comp, true));
      comp.setText(comp.config.props.confirm);
      comp.element.style.display = 'block';
    };

    var _config = {
      attribs: {
        property: 'data-property-name',
        value: 'data-property-value'
      },
      props: {
        confirm: 'confirmText',
        success: 'successText',
        error: 'errorText'
      },
      selectors: {
        property: '[data-property-name]',
        resend: '[data-resend]',
        text: '.doubleOptInToast_text',
        exit: '.doubleOptInToast_exit'
      },
      session: 'doubleOptInToast'
    };

    var _exit = function(saveProp) {
      if (saveProp) {
        // add current text to seen
        var saved = $window.sessionStorage.getItem(comp.config.session) || '';
        $window.sessionStorage.setItem(comp.config.session, saved + '|' + comp.text.confirmText);
      }

      comp.element.style['margin-top'] = -1 * comp.element.offsetHeight + 'px';

      $window.setTimeout(function() {
        comp.element.parentElement.removeChild(comp.element);
      }, 500);
    };

    var _resend = function() {
      app.ajax.get({
        url: '/verifyEmail.account',
        success: comp.onsuccess,
        error: comp.onerror
      });
    };

    var _onsuccess = function() {
      comp.setText(comp.config.props.success);
      $window.sessionStorage.setItem(comp.config.session, 'true');
    };

    var _onerror = function() {
      comp.setText(comp.config.props.error);
    };

    var _setText = function(property) {
      var value = comp.text[property];

      if (!value) {
        return;
      }

      comp.textEl.innerHTML = value;
      var resend = comp.textEl.querySelector(comp.config.selectors.resend);

      if (resend) {
        resend.addEventListener('click', comp.resend);
      }
    };

    comp.init = _init;
    comp.config = _config;
    comp.exit = _exit;
    comp.resend = _resend;
    comp.onsuccess = _onsuccess;
    comp.onerror = _onerror;
    comp.setText = _setText;

    return comp;
  };

  return doubleOptInToast;
});
